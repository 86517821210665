import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Menu } from "antd";
import "./menu.css";

const MenuLight = (props) => {
	
  let menus = props.menus && [
    // {
    //   id: 7,
    //   icon_path: "fund",
    //   name: "ESTADISTICAS",
    //   type: "local",
    //   path: "/estadisticas",
    // },
    ...props.menus
  ];
  

  return (
    <div className={props.className || "menu-light menu-principal"} style={{maxWidth:'1240px'}}>
      <Menu overflowedIndicator={<Icon type="menu" />} mode="horizontal">
        {menus.map((menu, i) => (
          <Menu.Item key={menu.name}>
            {/* {console.log(menu.name)} */}
            <Link to={menu.path}>
              {" "}
              <Icon type={menu.icon_path} />
              {menu.name}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};
export default withRouter(MenuLight);
