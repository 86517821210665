import React, { Component } from "react";
import { Avatar, Dropdown, Icon, Layout, Menu } from "antd";
import MenuLight from "../menu/MenuLight";
import { Link, withRouter } from "react-router-dom";
import "./header.css";
import { getUserInfo, logout } from "../../services";
import { menuService } from "../../services/services";
import { connect } from "react-redux";
import ReactGA from 'react-ga4';


const { Header } = Layout;
const Logo = require("../../resources/images/logo.png");


class HeaderHome extends Component {
	state = {
		selected: ["1"],
		full_name: null,
	};
	
	componentWillMount() {
		this.getUser();
	}
	getUser = async () => {
		getUserInfo().then((response) => {
			let { first_name, last_name } = response;
			let full_name = `${first_name ? first_name : ""} ${
				last_name ? last_name : ""
			}`;
			localStorage.setItem("user", JSON.stringify(response));
			this.setState({
				full_name,
			});
		});
	};


	getMenus = async () => {
		await menuService.find({ query: { status: 'active', $sort: { position: 1 } } })
			.then(({data}) => {
				this.setState({ menus: data })
			})
	}
	
	handleChange = ({ item, key }) => {
		this.setState({
			selected: [key],
		});
	};

	handleMenuItem = async (key) => {
		let { history } = this.props;

		
		getUserInfo().then(user => {

			ReactGA.event({
				category: 'User',
				action: 'Logout',
				label: 'Inicio de sesión exitoso',
				name: `${user.first_name} ${user.last_name}`,
				company: user.company.name,
				companyId:user.company.id
			  });

		})

		switch (key) {
			case "logout":
				await logout();
				window.location.href = "/";
				break;
			case "perfil":
				history.replace("/perfiles");
				break;
			default:
				break;
		}
	};
	render() {
		let { selected } = this.state;

		const menu = (
			<Menu>
				<Menu.Item onClick={() => this.handleMenuItem("perfil")} key="perfil">
					Perfil
				</Menu.Item>
				<Menu.Divider />
				<Menu.Item onClick={() => this.handleMenuItem("logout")} key="logout">
					Cerrar Sesión
				</Menu.Item>
				{/* <Menu.Item disabled className="item-menu-recent-projects">
      <Icon type="star" />
      Favoritos
    </Menu.Item>
    <Menu.Item>Item 1</Menu.Item>
    <Menu.Item>Item 2</Menu.Item>
    <Menu.Item>Item 3</Menu.Item> */}
			</Menu>
		);
		
		return (
			<Header
				className="header-home"
				theme="light"
				style={{
					/*  position: "fixed",
            zIndex: 1, */
					height: 70,
					width: "100%",
					background: "#fff",
				}}
			>
				<Link to="/home">
					<img className="logo-header" src={Logo} alt="logo" />
				</Link>
				<MenuLight
					onSelect={this.handleChange}
					theme="light"
					mode="horizontal"
					selectedKeys={selected}
					menus={this.props.ui.menus || []}
					/* defaultSelectedKeys={["1"]} */
					style={{ lineHeight: "64px" }}
				>
					<Menu.Item key="1">
						<Link to="/">Home</Link>
					</Menu.Item>
					<Menu.Item key="2">
						<Link to="/about">About</Link>
					</Menu.Item>
					<Menu.Item key="3">
						<Link to="/services">Services</Link>
					</Menu.Item>
				</MenuLight>
				<Menu
					className="menu-light menu-profile"
					mode="horizontal"
					style={{ lineHeight: "64px" }}
					theme="light"
				>
					<Menu.Item>
						<Dropdown className="menu-projects" overlay={menu}>
							<div>
								<span className="user-name">
									<Avatar
										style={{ backgroundColor: "#194d8c", color: "#fff" }}
										icon="user"
										shape="circle"
										size="default"
									/>
								</span>
								<Icon
									className="text-yellow"
									size="large"
									style={{
										fontSize: 28,
										verticalAlign: "middle",
									}}
									type="caret-down"
								/>
							</div>
						</Dropdown>
					</Menu.Item>
				</Menu>
			</Header>
		);
	}
}

const mapStateToProps = (state) => {
	const { ui } = state 
	return {
		ui
	}
}

export default connect(mapStateToProps)(withRouter(HeaderHome));
